import React from 'react'
import { Link } from "gatsby"

import Styled from "./nav.module.css"
import MenuItem from "./item"

function ServiceDropdown() {
    return (
        <>
            <div className={Styled.headerMenuDropdown}>
                <div>
                    <ul className={Styled.headerMenuDropdownLinks}>
                        <MenuItem><Link to="/services#automation">Robotic Process Automation / AI</Link></MenuItem>
                        <MenuItem><Link to="/services#outsourcing">Business Process Outsourcing</Link></MenuItem>
                        <MenuItem><Link to="/services#software">Software and Design</Link></MenuItem>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ServiceDropdown

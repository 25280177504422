import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.729 34.246L12.9 15.416a2.093 2.093 0 01-.019-2.95c.792-.792 2.15-.783 2.951.019l18.829 18.829c.831.831.81 2.159.019 2.95a2.093 2.093 0 01-2.951-.018z"
        fill="#010101"
      />
      <path
        d="M34.246 15.706l-18.83 18.829c-.83.831-2.158.81-2.95.019-.821-.821-.783-2.15.019-2.951l18.829-18.83a2.093 2.093 0 012.95-.018c.851.791.813 2.12-.018 2.95zM39.515 40.146c1.16 0 2.1-.928 2.1-2.073S40.675 36 39.515 36c-1.16 0-2.1.928-2.1 2.073s.94 2.073 2.1 2.073z"
        fill="#010101"
      />
    </svg>
  )
}

export default SvgComponent

import React, { useEffect } from "react"
import Header from "./Header/index.js"
import Footer from "./Footer/index.js"

function Layout({ children }) {
  useEffect(() => {
    document.body.style.overflow = "auto"
  }, [])
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default Layout

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FCFCFC"
        d="M12.015 4.574a.712.712 0 00-.836.567.719.719 0 00.564.841 2.885 2.885 0 012.287 2.292.716.716 0 00.838.568.72.72 0 00.564-.843 4.312 4.312 0 00-3.417-3.425"
      ></path>
      <path
        fill="#FCFCFC"
        d="M11.963 1.673a.681.681 0 00-.52.146.71.71 0 00-.27.487.716.716 0 00.631.791 5.76 5.76 0 015.105 5.116.714.714 0 101.42-.16c-.378-3.38-2.995-6.005-6.366-6.38M14.67 12.226c-.54-.115-.967.135-1.345.353-.388.225-1.126.82-1.547.668C9.615 12.36 7.58 10.474 6.7 8.312c-.155-.43.44-1.169.663-1.558.219-.379.464-.81.352-1.35-.1-.488-1.41-2.147-1.874-2.601-.306-.3-.618-.465-.94-.492C3.695 2.26 2.347 3.865 2.11 4.249c-.593.818-.589 1.907.01 3.228C3.563 11.025 9.025 16.38 12.6 17.871c.66.307 1.263.461 1.805.461.53 0 1.003-.147 1.409-.44.305-.175 1.983-1.585 1.938-2.82-.025-.315-.19-.63-.488-.934-.453-.466-2.108-1.81-2.593-1.912"
      ></path>
    </svg>
  );
}

export default Icon;

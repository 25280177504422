import React from "react"

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="#010101"
        d="M36.264 17.278H9.636c-1.176 0-2.1-.953-2.1-2.073s.966-2.073 2.1-2.073h26.628c1.176 0 2.1.954 2.1 2.073 0 1.12-.924 2.073-2.1 2.073zM36.264 27.727H9.636c-1.176 0-2.1-.953-2.1-2.073 0-1.16.966-2.073 2.1-2.073h26.628c1.176 0 2.1.954 2.1 2.073.042 1.161-.924 2.073-2.1 2.073zM35.802 38.218H9.174c-1.176 0-2.1-.954-2.1-2.074 0-1.12.966-2.073 2.1-2.073h26.628c1.176 0 2.1.954 2.1 2.074 0 1.119-.924 2.073-2.1 2.073zM41.094 11.514c1.16 0 2.1-.928 2.1-2.073s-.94-2.073-2.1-2.073c-1.16 0-2.1.928-2.1 2.073s.94 2.073 2.1 2.073z"
      ></path>
    </svg>
  )
}

export default Icon

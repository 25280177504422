import React from 'react'
import { Link } from "gatsby"

import Styled from "./nav.module.css"
import MenuItem from "./item"
import CardImage from "../../../assets/img/mega/services/base-picture.png"
import CardImageOverlayer from "../../../assets/img/mega/services/filter.png"



function ServiceDropdown() {
    return (
        <>
            <div className={Styled.headerMenuDropdown}>
                <div>
                    <ul className={Styled.headerMenuDropdownLinks}>
                        <MenuItem><Link to="/industries/outsourcing">Business Process Outsourcing</Link></MenuItem>
                        <MenuItem><Link to="/industries/accountancy-finance">Accounting and Finance</Link></MenuItem>
                        <MenuItem><Link to="/industries/property-management">Property Management and Real Estate</Link></MenuItem>
                        <MenuItem><Link to="/industries/professional-services">Professional Services</Link></MenuItem>
                        <MenuItem><Link to="/industries/construction">Construction</Link></MenuItem>
                        <MenuItem><Link to="/industries/retail">Retail</Link></MenuItem>
                    </ul>
                    {/* <div className={Styled.megaMenuCard}>
                        <div className={Styled.megaMenuCardImage}>
                            <img className={Styled.primary} src={CardImage} />
                            <img className={Styled.overlayer} src={CardImageOverlayer} />
                        </div>
                        <div className={Styled.megaMenuCardContent}>
                            <h6>Space exploration is now being fueled by business innovation</h6>
                            <p>Technologiess shaping the enterprise world, like cloud, IoT and blackchain, are proving just as important in outer space.</p>
                            <Link href="javascript:void(0)" className={Styled.megaMenuCardLink}>Read in blog</Link >
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default ServiceDropdown

import React from "react"
import { Link } from "gatsby"
import Styled from "./nav.module.css"
import SocialChannels from "../../../assets/img/social/social-channels.svg"
import MainSvg from "../../../assets/img/illustration-main.svg"

function Navbar() {
  return (
    <nav className={`${Styled.openNavWrapper} scroll-lock`}>
      <div className={`${Styled.wrapper} container`}>
        <img
          alt="Main SVG"
          width="473"
          height="314"
          style={{
            maxWidth: "100%",
            objectFit: "contain",
          }}
          src={MainSvg}
        ></img>
        <div className={Styled.menusWrapper}>
          <div className={`${Styled.menuWrapper}`}>
            <ul className={Styled.titleWrapper}>
              <li className={`${Styled.menuTitle}`}>
                <a target="_blank" href="https://www.globalintegra.com/Integra_Corporate_Profile.pdf">Online Catalog</a>
              </li>
              <li className={Styled.menuTitle}>
                <Link activeClassName="link-active" to="/contact">
                  Contact us
                </Link>
              </li>
            </ul>
            <ul className={Styled.subtitleWrapper}>
              {/* <li className={Styled.menuSubtitle}>
                <a href="javascript:void(0)">Joint Venture Partnership</a>
              </li> */}
              <li className={Styled.menuSubtitle}>
                <Link to="/data-security">Data Security</Link>
              </li>
              <li className={Styled.menuSubtitle}>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className={`${Styled.menuWrapper} hidden-from-md`}>
            <ul className={Styled.titleWrapper}>
              <li className={`${Styled.menuTitle}`}>
                <Link to="/careers">Careers</Link>
              </li>
              <li className={Styled.menuTitle}>
                <Link activeClassName="link-active" to="/services">
                  Services
                </Link>
              </li>
            </ul>
            <ul className={Styled.subtitleWrapper}>
              <li className={Styled.menuSubtitle}>
                <Link to="/industries">Industries</Link>
              </li>
              <li className={Styled.menuSubtitle}>
                <Link to="/about">About Us</Link>
              </li>
              <li className={Styled.menuSubtitle}>
                <Link to="https://www.globalintegra.com/blog/" target="_blank">Blog</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`${Styled.adressWrapper} container`}>
        <div className={Styled.adresscard}>
          <h1 className={Styled.adressTitle}>USA HQ</h1>
          <h2 className={Styled.adress}>
            7500 brooktree road <br />
            Pittsburgh <br /> Pennsylvania <br /> 15090 <br />
            USA
          </h2>
          <footer className={Styled.footer}>Phone: <a href="tel:+ 1 412 267 1529">+1 412 267 1529</a></footer>
        </div>
        <div lassName={Styled.adress}>
          <h1 className={Styled.adressTitle}>UK</h1>
          <h2 className={Styled.adress}>
            2nd Floor, College house <br />
            17, King Edwards road
            <br />
            Ruislip, London <br />
            Middlesex <br /> HA4 7AE
            <br /> UK
          </h2>
          <footer className={Styled.footer}>Phone: <a href="tel:+ 44 020 7993 2949">+ 44 020 7993 2949</a></footer>
        </div>
        <div className={Styled.adresscard}>
          <h1 className={Styled.adressTitle}>INDIA</h1>
          <h2 className={Styled.adress}>
            Kanapathy towers <br /> Sathy road
            <br /> Ganapathy
            <br /> Coimbatore
            <br /> 641006
            <br />
            India
          </h2>
          <footer className={Styled.footer}>Phone: <a href="tel:+91 0422 432 8555">+91 0422 432 8555</a></footer>
        </div>
        <div className={Styled.adresscard}>
          <h1 className={Styled.adressTitle}>PHILIPPINES</h1>
          <h2 className={Styled.adress}>
            Ayala Cebu Tower
            <br />
            14th Floor
            <br />
            Cebu Business Park
            <br />
            Cebu
            <br />
            6000
            <br />
            Philippines
          </h2>
          <footer className={Styled.footer}>Phone <a href="tel:+1 412-267-1529">+1 412-267-1529</a></footer>
        </div>
      </div>
      <footer className={`${Styled.navFooter} container`}>
        <h1 className="hidden-upto-md ">
          2004-2021 © Integra Global Solutions Corp. All rights reserved.
        </h1>
        <h1 className="hidden-from-md">
          2004-2021 © Integra Global Solutions Corp. <br /> All rights reserved.
        </h1>

        <div className={Styled.footerSocial}>
          <img alt="Social Channels" src={SocialChannels}></img>
        </div>
      </footer>
    </nav>
  )
}

export default Navbar

import React, { useState } from "react"
import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"

import ServiceDropdownMenu from './serviceDropdown2'
import IndustryDropdownMenu from './industryDropdown'
import Navbar from "./openNavbar"
import MenuItem from "./item"
import MenuOpen from "../../Icon/menu-open"
import MenuClose from "../../Icon/menu-close"
import Talk from "../../Icon/talk"
import Styled from "./styled.module.css"

function Index() {

  // const [showNav, setShowNav] = useState(false)

  const [state, setState] = useState({
    showNav: false,
    showServiceNav: false,
    showIndustryNav: false
  });

  const showNavDropdown = () => {
    setState({
      ...state,
      showServiceNav: false,
      showIndustryNav: false,
      showNav: !state.showNav
    });
    if (!state.showNav) {
      document.body.style.overflow = "hidden"
    } else {
      (document.body.style.overflow = "auto")
    }
  };

  // function showServiceDropdown() {
  //   setState({
  //     ...state,
  //     showNav: false,
  //     showIndustryNav: false,
  //     showServiceNav: !state.showServiceNav
  //   });
  //   if (!state.showServiceNav) {
  //     document.body.style.overflow = "hidden"
  //   } else {
  //     (document.body.style.overflow = "auto")
  //   }
  // }

  // function showIndustryDropdown() {
  //   setState({
  //     ...state,
  //     showNav: false,
  //     showServiceNav: false,
  //     showIndustryNav: !state.showIndustryNav
  //   });
  // }


  return (
    <header>
      <div className={Styled.headerWrapper}>
        <Link to="/">
          <img
            alt="Logo"
            src={require("../../../assets/img/logo-dark.svg")}
          ></img>
        </Link>
        <nav>
          <ul className={Styled.headerNav}>
            <MenuItem>
              <Link activeClassName={Styled.linkActive} to="/">
                Home
            </Link>
            </MenuItem>
            <li
              className={Styled.headerNavItem}
              onMouseEnter={() => setState({ showServiceNav: true }, document.body.style.overflow = "auto")}
              onMouseLeave={() => setState({ showServiceNav: false })}
            >
              <Link to="/services" activeClassName={Styled.linkActive} className={state.showServiceNav ? 'on-hover' : ''}>Services</Link>
              <CSSTransition
                timeout={200}
                in={state.showServiceNav}
                classNames="mega-menu-wrapper alert"
                unmountOnExit
              >
                <ServiceDropdownMenu />
              </CSSTransition>
            </li>
            <li
              className={Styled.headerNavItem}
              onMouseEnter={() => setState({ showIndustryNav: true }, document.body.style.overflow = "auto")}
              onMouseLeave={() => setState({ showIndustryNav: false })}
            >
              <Link to="/industries" activeClassName={Styled.linkActive}
                className={state.showIndustryNav ? 'on-hover' : ''}
              >
                Industries
            </Link>
              <CSSTransition
                timeout={200}
                in={state.showIndustryNav}
                classNames="mega-menu-wrapper alert"
                unmountOnExit
              >
                <IndustryDropdownMenu />
              </CSSTransition>
            </li>
            <MenuItem>
              <Link activeClassName={Styled.linkActive} to="/careers">
                Careers
            </Link>
            </MenuItem>
            <MenuItem>
              <Link activeClassName={Styled.linkActive} to="/about">
                About us
            </Link>
            </MenuItem>
            <MenuItem>
              <Link activeClassName={Styled.linkActive} to="/contact">
                Contact
            </Link>
            </MenuItem>
          </ul>
        </nav>
        <div className={Styled.headerNavContactWrapper}>
          <button
            onClick={showNavDropdown}
            className={Styled.headerHamburger}
            aria-label="Hamburger Menu"
          >
            {state.showNav ? <MenuClose /> : <MenuOpen />}
          </button>
          <a
            href="tel:+ 1 412 267 1529"
            aria-label="Talk"
            tabIndex={0}
            className={Styled.letsTalk}
          >
            Let’s Talk
            <Talk />
          </a>
        </div>
      </div>
      <CSSTransition
        timeout={300}
        in={state.showNav}
        classNames="alert"
        unmountOnExit
      >
        <Navbar />
      </CSSTransition>

    </header >
  )
}

export default Index

import React from "react"
import Styled from "./styled.module.css"

function MenuItem({ children }) {
  return (
    <li className={Styled.headerNavItem}>
      {children}
    </li>
  )
}

export default MenuItem

import React from "react"
import { Link } from "gatsby"
import LogoGrad from "../../../assets/img/logo-grad.svg"
import Styled from "./styled.module.css"
import Facebook from "../../../assets/img/social/facebook.svg"
import Linkedin from "../../../assets/img/social/linkedin.svg"
import Twitter from "../../../assets/img/social/twitter.svg"
import Youtube from "../../../assets/img/social/youtube.svg"
import CookieConsent from "react-cookie-consent"

function Footer() {
  return (
    <footer className={`${Styled.footer}`} id="footer">
      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="gatsby-cookie-consent"
        expires={1}
        buttonClasses=""
        containerClasses={`${Styled.cookieContainer}`}
        contentClasses={`${Styled.cookieContent}`}
      >
        We use cookies to provide the services and features offered on our
        website, and to improve our user experience. By continuing to use this
        site you consent to the use of{" "}
        <Link to="/cookies">
          <u>cookies</u>.
        </Link>
      </CookieConsent>

      <div className={`${Styled.footerWrapper}  container footer-row`}>
        <div className={Styled.footerLogo}>
          <img alt="Logo" src={LogoGrad}></img>
          <p className={Styled.text}>
            Good people making a positive difference to companies worldwide
          </p>
          <Link to="/contact" className={`explore ${Styled.ouroffice}`}>
            Our offices
          </Link>
        </div>
        <div className={Styled.footerMenus}>
          <div className={Styled.footerMenu}>
            <h1 className={`${Styled.menuTitle} sm-text bold`}>Industries</h1>
            <ul className={Styled.menuWrapper}>
              <li>
                <Link to="/industries/accountancy-finance" className="sm-text">
                  Accounting
                </Link>
              </li>
              <li>
                <Link to="/industries/property-management" className="sm-text">
                  Property Management
                </Link>
              </li>
              <li>
                <Link to="/industries/construction" className="sm-text">
                  Construction
                </Link>
              </li>
              <li>
                <Link to="/industries/outsourcing" className="sm-text">
                  BPO
                </Link>
              </li>
              <li>
                <Link to="/industries/retail" className="sm-text">
                  Retail
                </Link>
              </li>
              <li>
                <Link
                  to="/industries/professional-services"
                  className="sm-text"
                >
                  Professional services
                </Link>
              </li>
            </ul>
          </div>

          <div className={Styled.footerMenu}>
            <h1 className={`${Styled.menuTitle} sm-text bold`}>Services</h1>
            <ul className={Styled.menuWrapper}>
              <li>
                <Link to="/services#automation" className="sm-text">
                  Automation
                </Link>
              </li>
              <li>
                <Link to="/services#outsourcing" className="sm-text">
                  BPO/KPO
                </Link>
              </li>
              <li>
                <Link to="/services#software" className="sm-text">
                  Software & Design
                </Link>
              </li>
            </ul>
          </div>

          <div className={Styled.footerMenu}>
            <h1 className={`${Styled.menuTitle} sm-text bold`}>Company</h1>
            <ul className={Styled.menuWrapper}>
              <li>
                <Link to="/about" className="sm-text">
                  About
                </Link>
              </li>
              <li>
                <Link to="/industries" className="sm-text">
                  Industries
                </Link>
              </li>
              <li>
                <Link to="/careers" className="sm-text">
                  Careers
                </Link>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.globalintegra.com/blog/"
                  className="sm-text"
                >
                  Blog
                </a>
              </li>
              <li>
                <Link to="/privacy-policy" className="sm-text">
                  Privacy
                </Link>
              </li>
              <li>
                <Link to="/data-security" className="sm-text">
                  Data Security
                </Link>
              </li>
              <li>
                <Link className="sm-text" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div className={Styled.footerMenu}>
            <h1 className={`${Styled.menuTitle} sm-text bold`}>Get in touch</h1>
            <ul className={Styled.menuWrapper}>
              <li className="xs-text">
                Feel free to get in touch with us via phone directly.
              </li>
              <li>
                <a
                  href="tel:+ 1 412 267 1529"
                  className={`${Styled.contact} ${Styled.menuLink}`}
                >
                  + 1 412 267 1529
                </a>
              </li>
              <li>
                <Link to="/contact" className={`explore ${Styled.ouroffice}`}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`${Styled.footerTextWrapper} container`}>
        <h1 className="hidden-upto-md xs-text ">
          2004-2021 © Integra Global Solutions Corp. All rights reserved.
        </h1>
        <h1 className="hidden-from-md xs-text">
          2004-2021 © Integra Global Solutions Corp. <br /> All rights reserved.
        </h1>
        <div className={Styled.footerSocial}>
          <a
            target="_blank"
            href="https://www.facebook.com/IntegraGlobalSolutions"
          >
            <img alt="Facebook Logo" src={Facebook}></img>
          </a>

          <a target="_blank" href="https://twitter.com/integra_tweet">
            <img alt="Twitter Logo" src={Twitter}></img>
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/user/IntegraGlobalSolns?feature=mhee"
          >
            <img alt="Youtube Logo" src={Youtube}></img>
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/integra-global-solutions-corp"
          >
            <img alt="linkedIn Logo" src={Linkedin}></img>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
